<template>
  <div
    v-if="isLoggedIn || $route.path === '/internal/chatReport' && $route.query.authorization"
    id="internal"
  >
    <router-view />
  </div>
  <!-- ===== LOGIN ===== -->
  <LoginBasic v-else />
</template>

<script>
import { mapGetters } from 'vuex';
import LoginBasic from './Login/LoginBasic.vue';

export default {
  name: 'Internal',
  components: {
    LoginBasic,
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
    ]),
  },
};
</script>

<style lang="scss">
@import '../styles/variables/_colors.scss';
@import '../styles/variables/_sizes.scss';
@import '../styles/theme/_default.scss';

#internal {
  height: inherit;
  padding: 30px 0 30px 0;
}
</style>
