<template>
  <div v-if="initializing">
    <b-spinner
      variant="primary"
      label="Loading"
      class="initializing"
    />
  </div>
  <div
    v-else
    id="login-basic"
  >
    <FormErrors />
    <div>
      <b-button
        class="internal-button"
        variant="primary"
        size="lg"
        @click="signIn"
      >
        Login with Okta
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import API from '@/api';
import { getAuth, getRedirectResult, OAuthProvider, signInWithPopup } from '@firebase/auth';
import { app } from '../../utils/firebase';

import FormErrors from '../Form/FormErrors.vue';

export default {
  name: 'LoginBasic',
  components: {
    FormErrors,
  },
  data() {
    return {
      initializing: true,
      formDisabled: false,
    };
  },
  computed: {
    ...mapGetters([
      'errors',
      'isEmployee',
    ]),
  },
  async mounted() {
    const userCredential = await getRedirectResult(getAuth(app));
    if (userCredential != null) {
      await this.handleRedirectResult(userCredential);
    } else if (this.$session.exists()) {
      if (this.$session.get('sales-attribution')) this.$store.commit('UPDATE_SALES_ATTRIBUTION', this.$session.get('sales-attribution'));
      if (this.$session.get('emp')) this.$store.commit('TOGGLE_IS_EMPLOYEE', true);
      if (this.$session.get('platformToken')) API.setSsoToken(this.$session.get('platformToken'));

      try {
        const response = await this.$store.dispatch('validateUserToken');

        if (response) {
          console.log('token validated');
        } else {
          // login failed, destroy session and cached data (session, sales attribution, isEmployee)
          this.$session.destroy();
          this.initializing = false;
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      this.initializing = false;
    }
  },
  methods: {
    progress() {
      return { full: true };
    },
    async signIn() {
      const userCredential = await signInWithPopup(getAuth(app), new OAuthProvider('oidc.okta'));
      await this.handleRedirectResult(userCredential);
    },
    async handleRedirectResult(userCredential) {
      this.$store.commit('CLEAR_ERRORS');

      try {
        const platformToken = await this.$store.dispatch('login', {
          authProvider: 'FIREBASE',
          login: userCredential.user.email,
          loginfrom: 'SSP',
          password: await userCredential.user.getIdToken(),
        });

        if (platformToken) {
          this.$session.start();
          this.$session.set('platformToken', platformToken);
        }
      } catch (error) {
        console.error(error);
        this.$store.commit('PUSH_ERRORS', [`Error: ${error}`]);
      } finally {
        this.initializing = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

#login-basic {
  padding: 40px;
}

#signup-form-errors {
  position: inherit;
}

</style>
